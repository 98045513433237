import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import { TestimonialCard } from "../../components";
import { useGetTestimonial, usePostTestimonial } from "../../hooks";
import { Spin } from "antd";

const TestimonialEdit = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetTestimonial(id);
  const [
    editTestimonial,
    { isLoading: isPostTestimonialLoading },
  ] = usePostTestimonial(id);
  const { languages } = useSelector((state) => state.locale);
  const fillTranslations = (languages, values): any => {
    const translations = {};
    Object.values(languages).map((item: any) => {
      translations[item.code] = {
        title: values[`translations[${item.code}][title]`],
        lang: values[`translations[${item.code}][lang]`],
        url: values[`translations[${item.code}][url]`],
        content: values[`translations[${item.code}][content]`],
      };
    });
    return translations;
  };
  const handleSubmit = (values) => {
    for (let i in values) {
      if (values[i] === null || values[i] === undefined) values[i] = "";
      if (i === "visible") {
        values[i] = values[i] ? 1 : 0;
      }
    }
    const formData = new FormData();
    for (const i in values) {
      formData.append(i, values[i]);
    }
    // formData.append(
    //   "translations",
    //   JSON.stringify({ translations: fillTranslations(languages, values) })
    // );
    editTestimonial(formData);
  };
  return (
    <MainLayout>
      <PageTemplate>
        <Spin spinning={isLoading || isPostTestimonialLoading}>
          {data && (
            <TestimonialCard
              initialValues={data.data}
              handleSubmit={handleSubmit}
              isEdit
            />
          )}
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default TestimonialEdit;
