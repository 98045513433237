import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Switch,
  Typography,
} from "antd";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import { TestimonialContentCardType } from "./types";
import { TestimonialPrioritySelect } from "../TestimonialPrioritySelect";
import { UploadOutlined } from "@ant-design/icons";
import { setFormInputValue } from "../../../../../../common/helpers";

const TestimonialCard: React.FC<TestimonialContentCardType> = ({
  initialValues,
  isEdit,
  handleSubmit,
}) => {
  const [form] = Form.useForm();
  const [isSharedInputValues, setIsSharedInputValues] = useState(false);
  const history = useHistory();
  const { languages } = useSelector((state) => state.locale);
  const [imagePreview, setImagePreview] = useState(initialValues?.image);

  const uploadImage = (files) => {
    const image = files[0];
    if (!image) return false;
    setFormInputValue("image", image, form);
    setImagePreview(URL.createObjectURL(image));
  };

  const handleShareValues = (changedValues, allValues) => {
    if (!isSharedInputValues) return;
    const key = Object.keys(changedValues)[0];
    if (key.endsWith("[title]")) {
      Object.values(languages).map((item: any) => {
        form.setFieldsValue({
          [`translations[${item.code}][title]`]: changedValues[key],
        });
      });
    }
    if (key.endsWith("[url]")) {
      Object.values(languages).map((item: any) => {
        form.setFieldsValue({
          [`translations[${item.code}][url]`]: changedValues[key],
        });
      });
    }
    if (key.endsWith("[content]")) {
      Object.values(languages).map((item: any) => {
        form.setFieldsValue({
          [`translations[${item.code}][content]`]: changedValues[key],
        });
      });
    }
  };

  const pageTitle = isEdit
    ? initialValues?.name
    : _t("testimonial_create_name");

  return (
    <Card className="inner-form-card">
      <div className="inner-form-card__top">
        <Typography.Title level={4}>{pageTitle}</Typography.Title>
      </div>
      <Form
        onFinish={handleSubmit}
        layout="vertical"
        form={form}
        onValuesChange={handleShareValues}
      >
        {isEdit && <Form.Item name="id" initialValue={initialValues?.id} />}
        <Typography.Title level={5} style={{ marginBottom: "15px" }}>
          {_t("testimonial_card_content_title")}
        </Typography.Title>
        <Form.Item
          name="name"
          label={_t("testimonial_placeholder_name")}
          initialValue={initialValues?.name}
          rules={[
            {
              required: true,
              message: _t("name_field_required"),
            },
          ]}
        >
          <Input placeholder={_t("testimonial_placeholder_name")} />
        </Form.Item>
        <TestimonialPrioritySelect initialValue={initialValues?.priority} />
        <Form.Item
          name="sort"
          label={_t("testimonial_placeholder_sort")}
          initialValue={initialValues?.sort}
          rules={[
            {
              required: true,
              message: _t("sort_field_required"),
            },
          ]}
        >
          <Input
            type={"number"}
            placeholder={_t("testimonial_placeholder_sort")}
          />
        </Form.Item>
        <Form.Item
          name="visible"
          label={_t("is_testimonial_visible")}
          initialValue={!!initialValues?.visible}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Divider />
        <Row>
          <Col>
            <Typography.Title level={5} style={{ marginBottom: "15px" }}>
              {_t("testimonials_card_languages_title")}
            </Typography.Title>
          </Col>
        </Row>
        <Checkbox
          onClick={() => setIsSharedInputValues(!isSharedInputValues)}
          style={{ marginBottom: "20px" }}
        >
          {_t("same_text_for_fields")}
        </Checkbox>
        <Row gutter={14}>
          {languages &&
            Object.values(languages).map((item: any) => (
              <Col lg={12} span={24} key={item.id}>
                <Form.Item
                  name={`translations[${item.code}][lang]`}
                  initialValue={item.code}
                />
                <Form.Item
                  name={`translations[${item.code}][title]`}
                  label={item.name}
                  initialValue={initialValues?.translations[item.code]?.title}
                  rules={[
                    {
                      required: true,
                      message: _t("translation_field_required"),
                    },
                  ]}
                >
                  <Input placeholder={_t("testimonial_placeholder_title")} />
                </Form.Item>
                <Form.Item
                  name={`translations[${item.code}][url]`}
                  initialValue={initialValues?.translations[item.code]?.url}
                  rules={[
                    {
                      type: "url",
                      required: true,
                      // message: _t("translation_field_required"),
                    },
                  ]}
                >
                  <Input placeholder={_t("testimonial_placeholder_text")} />
                </Form.Item>
                <Form.Item
                  name={`translations[${item.code}][content]`}
                  initialValue={initialValues?.translations[item.code]?.content}
                  rules={[
                    {
                      required: true,
                      message: _t("translation_field_required"),
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={5}
                    placeholder={_t("testimonial_placeholder_content")}
                  />
                </Form.Item>
              </Col>
            ))}
        </Row>

        <Form.Item name="image">
          <Input.Group>
            <label className="upload-button">
              <input
                type="file"
                accept="image/*"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  uploadImage(e.target.files)
                }
              />
              <span className="ant-btn ant-btn-round">
                <UploadOutlined />
                Image
              </span>
            </label>
          </Input.Group>
        </Form.Item>
        <div style={{ width: "250px" }}>
          <img src={imagePreview} alt="" />
        </div>
        <div className="inner-form-card__controls">
          <Button size="large" shape="round" onClick={() => history.goBack()}>
            {_t("Back")}
          </Button>
          <Button size="large" shape="round" type="primary" htmlType="submit">
            {_t("submit")}
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default TestimonialCard;
